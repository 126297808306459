body {
    &.is_home {
        background-image: linear-gradient(180deg, #061C49 40.63%, #041A35 75.52%);
    }
}

.sd-banner {
    position: relative;

    img, video, iframe {
        min-height: 90vh;
        width: 100%;
    }

    .panel {
        background-color: black;
        opacity: 0.4;
        position: absolute;
        left: 0;
        top: 0;
        height: 99%;
        width: 100%;
    }

    .container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;

        h1 {
            font-family: $fontTitle;
            font-size: 40px;
            font-weight: 600;
            color: white;
            text-align: center;
            @include media-breakpoint-up(md) {
                font-size: 72px;
            }
        }

        small {
            font-family: $fontBase;
            font-size: 16px;
            font-weight: normal;
            letter-spacing: 6.4px;
            text-transform: uppercase;
            display: block;
            margin-bottom: 10px;
        }
    }

    .black-panel {
        background-image: url("/images/back-panel.webp");
        background-size: auto 100%;
        height: 50px;
        transform: rotate(180deg);
        position: absolute;
        bottom: 0;
        width: 100%;

        @include media-breakpoint-up(md) {
            height: 80px;
        }
    }
}

.sd-block-1 {
    background-image: url("/images/background-block-1.webp");
    background-position: center 0;
    background-size: auto 100%;
    background-repeat: no-repeat;
    padding-top: 80px;

    @include media-breakpoint-up(md) {
        padding-bottom: 200px;
    }

    .black-panel {
        background-image: url("/images/back-panel.webp");
        background-size: auto 100%;
        height: 50px;
        position: relative;
        top: -80px;
        left: 0;
        width: 100%;

        @include media-breakpoint-up(md) {
            height: 80px;
            top: -80px;
        }
    }

    #particles-js-block-1 {
        display: none;
        height: 660px;
        width: 220px;
        left: 0;
        position: absolute;
        z-index: 0;

        @include media-breakpoint-up(md) {
            display: block;
        }
    }

    #particles-js-2-block-1 {
        display: none;
        height: 1260px;
        width: 220px;
        right: 0;
        position: absolute;
        z-index: 1;

        @include media-breakpoint-up(md) {
            display: block;
        }
    }

    #particles-js-block-drone {
        display: none;
        height: 600px;
        width: 100%;
        left: 0;
        position: absolute;
        z-index: 1;

        @include media-breakpoint-up(md) {
            display: block;
        }
    }

    #drone {
        transition: all 2s ease;

        &.animate {
            transform: translateY(80%);
        }
    }

    .wrap-us {
        .title {
            display: block;
            text-align: left;
            margin: 0 auto;
            width: 300px;

            @include media-breakpoint-up(lg) {
                width: 450px;
            }
        }

        .text {
            margin: 28px auto 30px;
            width: 300px;

            @include media-breakpoint-up(md) {
                margin: 28px auto 60px;
            }
            @include media-breakpoint-up(lg) {
                width: 450px;
            }
        }

        &.down {
            .text {
                z-index: 1;
                position: relative;
                @include media-breakpoint-up(lg) {
                    margin-top: 100px;
                }
            }
        }
    }

    .us-slider {
        border-radius: 15px;
        overflow: hidden;

        @include media-breakpoint-up(md) {
            width: 555px;
        }
        @include media-breakpoint-up(lg) {
            margin: 0 auto;
        }
    }

    .drone {
        display: block;
        margin: 0 auto 140px;
        width: 265px;

        @include media-breakpoint-up(md) {
            margin-top: -30px;
            width: 300px;
        }
        @include media-breakpoint-up(lg) {
            margin-top: -45px;
            width: 477px;
        }
    }
}

//.sd-block-1

.sd-block-2 {
    background-image: url("/images/background-block-2-xs.webp");
    background-position: center 0;
    background-size: auto 100%;
    background-repeat: no-repeat;
    padding-bottom: 90px;
    padding-top: 105px;

    @include media-breakpoint-up(md) {
        background-image: url("/images/background-block-2.webp");
        background-size: cover;
        background-position: 0 0;
    }
    @include media-breakpoint-up(lg) {
        //background-size: 100% auto;
    }


    .wrap-best {
        margin-bottom: 80px;

        .title {
            display: block;
            text-align: center;
            margin: 0 auto;
            width: 300px;

            @include media-breakpoint-up(md) {
                width: 430px;
            }
            @include media-breakpoint-up(lg) {
                width: 530px;
            }
        }

        .text {
            text-align: center;
            margin: 10px auto 30px;
            width: 300px;

            @include media-breakpoint-up(md) {
                width: 640px;
            }
        }
    }

    .container-drones-videos {
        margin: 0 auto;
        width: 100%;

        .item-video {
            padding: 0;

            video {
                display: block;
                margin: 0 auto;
                width: 90%;
            }

            .title {
                font-size: 26px;
                text-align: center;
                margin-bottom: 45px;
                margin-top: 15px;
            }
        }

        .disclaimer {
            color: white;
            font-size: 14px;
            font-style: italic;
            font-weight: 600;
            text-align: center;
            margin: 0 auto 60px;
            width: 300px;

            @include media-breakpoint-up(md) {
                font-size: 18px;
                width: 100%;
            }
        }
    }

    .wrap-contact-us {
        @include media-breakpoint-up(md) {

            order: 1;
        }

        .wrap-group {
            @include media-breakpoint-up(lg) {
                margin: 0 auto;
                display: block;
                max-width: 380px;
            }

            .text {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                margin: 28px auto 30px;
                text-align: center;
                width: 265px;

                @include media-breakpoint-up(md) {
                    font-size: 18px;
                    text-align: left;
                    margin-top: 200px;
                    line-height: 22px;
                    width: 100%;
                }
                @include media-breakpoint-up(lg) {
                    text-align: left;
                    margin-left: 0;
                    width: 380px;
                }
            }

            a {
                margin: 30px auto 30px;
                width: 240px;

                @include media-breakpoint-up(md) {
                    margin-left: 0;
                }
            }
        }
    }

    .wrap-contact-img {
        @include media-breakpoint-up(md) {
            order: 2;
        }

        img {
            max-width: 250px;
            margin: 30px auto;
            display: block;

            @include media-breakpoint-up(md) {
                margin: 50px auto 0;
                display: block;
                max-width: 340px;
            }
            @include media-breakpoint-up(lg) {
                margin: 10px auto 0;
                max-width: 380px;
            }
        }
    }

}

//.sd-block-2

.sd-block-3 {
    background-image: url("/images/background-block-3-xs.webp");
    background-position: center bottom;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-color: white;
    padding-top: 75px;
    position: relative;

    @include media-breakpoint-up(md) {
        background-image: url("/images/background-block-3.webp");
    }
    @include media-breakpoint-up(lg) {
    }

    #particles-js-block-3 {
        display: none;
        height: 660px;
        width: 220px;
        right: 0;
        position: absolute;
        top: -200px;
        z-index: 1;

        @include media-breakpoint-up(md) {
            display: block;
        }
    }

    #particles-js-2-block-3 {
        display: none;
        height: 660px;
        width: 220px;
        left: 0;
        position: absolute;
        top: 300px;
        z-index: 1;

        @include media-breakpoint-up(md) {
            display: block;
        }
    }

    .wrap-include {

        .title {
            color: $brand-secondary;
            text-align: left;
            margin: 0 auto 70px;
            width: 300px;

            @include media-breakpoint-up(md) {
                margin-left: 0;
            }
            @include media-breakpoint-up(lg) {
                width: 440px;
            }

            span {
                color: $brand-light;
            }
        }
    }

    .wrap-include-items {
        margin-bottom: 30px;
        @include media-breakpoint-up(md) {
            margin-bottom: 60px;
            border-radius: 10px;
            background: rgba(255, 255, 255, 0.30);
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        }
        @include media-breakpoint-up(lg) {
                padding-top: 66px;
        }
        @include media-breakpoint-up(xl) {
            margin-left: 15%;
            max-width: 700px;
        }

        .main-item {
           margin-bottom: 66px;


            .item {
                margin-top: 20px;
                min-height: 50px;
                display: flex;
                position: relative;
                padding-bottom: 27px;

                @include media-breakpoint-up(lg) {
                    margin-top: 18px;
                    padding-bottom: 18px;
                }


                .w-img {
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 35%;

                    @include media-breakpoint-up(lg) {
                        align-items: center;
                        width: 17%;
                    }

                    img {
                        display: block;
                        margin: 0 auto;
                        width: auto;
                        max-width: 90px;
                    }

                }

                .w-img.img2 {
                    img {
                        max-width: 59px;
                    }
                }

                .w-img.img3 {
                    img {
                        max-width: 56px;
                    }
                }

                .w-img.img4 {
                    img {
                        max-width: 57px;
                    }
                }

                .w-img.img5 {
                    img {
                        max-width: 64px;
                    }
                }

                .w-img.img6 {
                    img {
                        max-width: 50px;
                    }
                }

                .w-img.img7 {
                    img {
                        max-width: 51px;
                    }
                }

                .w-img.img8 {
                    img {
                        max-width: 48px;
                    }
                }

                .w-img.img9 {
                    img {
                        max-width: 63px;
                    }
                }

                .w-img.img10 {
                    img {
                        max-width: 59px;
                    }
                }

                .w-text {
                    text-align: left;
                    display: flex;
                    flex-direction: column;
                    margin-left: 5%;
                    width: 60%;

                    @include media-breakpoint-up(md) {
                        width: 50%;
                    }
                    @include media-breakpoint-up(lg) {
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        width: 75%;
                        margin-left: 0;
                    }

                    label {
                        text-transform: uppercase;

                        &.desc {
                            color: $brand-secondary;
                            font-size: 16px;
                            line-height: 20px;
                            font-style: normal;
                            font-weight: 500;

                            @include media-breakpoint-up(lg) {
                                width: 50%;
                            }
                        }

                        &.txt {
                            color: $brand-light;
                            font-size: 16px;
                            line-height: 20px;
                            font-style: normal;
                            font-weight: 400;

                            @include media-breakpoint-up(lg) {
                                width: 50%;
                            }
                        }
                    }
                }

                .line {
                    background-color: #4F86C6;
                    margin: 0 auto;
                    height: 1px;
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    width: 90%;

                    @include media-breakpoint-up(lg) {
                        margin-left: 18%;
                        width: 75%;
                    }
                }
            }
        }

        .slick-arrow {
            display: block;
            background-image: url("/images/arrow.webp");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 100% auto;
            margin-top: 0;
            height: 30px;
            left: 35%;
            top: 94%;
            width: 30px;
            z-index: 100;

            @include media-breakpoint-up(md) {
                margin-left: -25px;
                left: -15px;
                top: 50%;
            }

            &:before {
                content: "";
            }

            &.slick-next {
                transform: rotate(180deg);
                right: 35%;
                left: auto;
                margin: 0;
                top: 90%;

                @include media-breakpoint-up(md) {
                    margin-left: 0;
                    margin-right: -25px;
                    top: 50%;
                    right: -15px;
                }
            }
        }

    }

}

//.sd-block-3

.sd-block-4 {
    background-image: url("/images/background-block-4-xs.webp");
    background-position: center bottom;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    padding-top: 75px;

    @include media-breakpoint-up(md) {
        background-position: 0 0;
        background-image: url("/images/background-block-4.webp");
    }
    @include media-breakpoint-up(lg) {
    }

    #particles-js-block-4 {
        display: none;
        height: 660px;
        width: 220px;
        right: 0;
        position: absolute;
        top: 0;
        z-index: 1;

        @include media-breakpoint-up(md) {
            display: block;
        }
    }

    #particles-js-2-block-4 {
        display: none;
        height: 660px;
        width: 220px;
        left: 0;
        position: absolute;
        bottom: 0;
        z-index: 1;

        @include media-breakpoint-up(md) {
            display: block;
        }
    }

    #drone2 {
        transition: all 2s ease;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;


        @include media-breakpoint-up(md) {
            right: 0;
            left: auto;
        }

        @media (min-width: 1400px) {
            //transform: translateY(-80%);
        }

        &.animate {
            transform: translateY(-80%);

            @media (min-width: 1400px) {
                //transform: translateY(0);
            }
        }
    }

    .wrap-technology {

        .title {
            text-align: left;
            margin: 0 auto 70px;
            width: 300px;

            @include media-breakpoint-up(md) {
                margin-left: 0;
            }
            @include media-breakpoint-up(lg) {
                width: 380px;
            }
        }
    }

    .wrap-technology-items {
        border-radius: 10px;
        background-color: rgba(19, 61, 124, 0.5);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        margin-bottom: 105px;
        padding-top: 20px;

        @include media-breakpoint-up(lg) {
            padding-top: 50px;
        }

        @include media-breakpoint-up(xl) {
            max-width: 700px;
        }

        .main-item {
            .item {
                margin-top: 20px;
                min-height: 50px;
                display: flex;
                position: relative;
                padding-bottom: 27px;

                @include media-breakpoint-up(lg) {
                    margin-top: 18px;
                    padding-bottom: 18px;
                }

                &:last-child {
                    margin-bottom: 66px;

                    @include media-breakpoint-up(lg) {
                        padding-bottom: 50px;
                    }
                }

                .w-img {
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 35%;

                    @include media-breakpoint-up(lg) {
                        align-items: center;
                        width: 17%;
                    }

                    img {
                        display: block;
                        margin: 0 auto;
                        width: auto;
                        max-width: 57px;
                    }

                }

                .w-img.img2 {
                    img {
                        max-width: 49px;
                    }
                }

                .w-img.img3 {
                    img {
                        max-width: 71px;
                    }
                }

                .w-img.img4 {
                    img {
                        max-width: 76px;
                    }
                }

                .w-img.img5 {
                    img {
                        max-width: 83px;
                    }
                }

                .w-img.img6 {
                    img {
                        max-width: 63px;
                    }
                }

                .w-img.img7 {
                    img {
                        max-width: 51px;
                    }
                }

                .w-img.img8 {
                    img {
                        max-width: 76px;
                    }
                }

                .w-text {
                    text-align: left;
                    display: flex;
                    flex-direction: column;
                    margin-left: 5%;
                    width: 60%;

                    @include media-breakpoint-up(lg) {
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        width: 75%;
                        margin-left: 0;
                    }

                    label {
                        text-transform: uppercase;
                        color: white;

                        &.desc {
                            font-size: 16px;
                            line-height: 20px;
                            font-style: normal;
                            font-weight: 500;

                            @include media-breakpoint-up(lg) {
                                width: 50%;
                            }

                        }

                        &.txt {
                            font-size: 16px;
                            line-height: 20px;
                            font-style: normal;
                            font-weight: 400;

                            @include media-breakpoint-up(lg) {
                                width: 45%;
                            }
                        }
                    }
                }

                .line {
                    background-color: white;
                    margin: 0 auto;
                    height: 1px;
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    width: 90%;

                    @include media-breakpoint-up(lg) {
                        margin-left: 16%;
                        width: 75%;
                    }
                }
            }
        }

        .slick-arrow {
            display: block;
            background-image: url("/images/white-arrow.webp");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 100% auto;
            margin-top: 0;
            height: 30px;
            width: 30px;
            left: 35%;
            top: 93%;
            z-index: 100;

            @include media-breakpoint-up(md) {
                margin-left: -25px;
                top: 89%;
            }

            &:before {
                content: "";
            }

            &.slick-next {
                transform: rotate(180deg);
                right: 35%;
                left: auto;
                top: 90%;
                margin: 0;

                @include media-breakpoint-up(md) {
                    margin-left: 0;
                    margin-right: -25px;
                    top: 86%;
                }
            }
        }
    }
}

//.sd-block-4

.sd-block-5 {
    background-image: url("/images/background-block-5.webp");
    background-position: center bottom;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: white;
    padding-top: 75px;

    @include media-breakpoint-up(md) {
        padding-bottom: 93px;
    }

    .wrap-instagram {

        .title {
            color: $brand-secondary;
            text-align: center;
            margin: 0 auto 70px;
            width: 300px;

            @include media-breakpoint-up(md) {
                width: 400px;
            }

            @include media-breakpoint-up(lg) {
                width: 515px;
            }

            span {
                color: $brand-light;
            }
        }
    }

    .instagram-slider {
        .col-md-12 {
            @include media-breakpoint-up(md) {
                padding: 0;
            }
        }
    }

    .slider {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 83px;

        @include media-breakpoint-up(md) {
            position: absolute;
            left: 0;
            right: 0;
            transform: translate(0, -100%);
            justify-content: space-between;
            width: 100%;
        }

        @include media-breakpoint-up(lg) {
            transform: translate(0, -145%);
        }

        a {
            display: block;
            background-image: url("/images/arrow.webp");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 100% auto;
            margin-top: 25px;
            height: 30px;
            width: 30px;

            @include media-breakpoint-up(md) {
                margin-left: -25px;
            }

            &.slider-next {
                transform: rotate(180deg);

                @include media-breakpoint-up(md) {
                    margin-left: 0;
                    margin-right: -25px;
                }
            }
        }
    }

}

//sd-block-5

.sd-block-6 {
    background-image: url("/images/background-block-6-xs.webp");
    background-position: 0 0;
    background-size: 100% auto;
    background-repeat: no-repeat;
    padding-bottom: 85px;
    padding-top: 75px;
    position: relative;

    #particles-js-block-6 {
        display: none;
        height: 660px;
        width: 220px;
        right: 0;
        position: absolute;
        top: -200px;
        z-index: 0;

        @include media-breakpoint-up(md) {
            display: block;
        }
    }

    #particles-js-2-block-6 {
        display: none;
        height: 660px;
        width: 220px;
        left: 0;
        position: absolute;
        top: 300px;
        z-index: 0;

        @include media-breakpoint-up(md) {
            display: block;
        }
    }

    @include media-breakpoint-up(md) {
        background-image: url("/images/background-block-6.webp");
        background-position: 0 0;
        background-size: cover;
    }
    @include media-breakpoint-up(lg) {
        background-size: 100% auto;
    }

    .wrap-blog {

        .title {
            text-align: left;
            margin: 0 auto 70px;
            width: 300px;

            @include media-breakpoint-up(md) {
                margin-left: 0;
                width: 400px;
            }

            @include media-breakpoint-up(lg) {
                width: 540px;
            }
        }
    }

    .blog-slider {

        .slick-slide {
            @include media-breakpoint-up(md) {
                margin-left: 20px;
                margin-right: 20px;
            }
        }

        .item {

            img {
                width: 100%;
            }

            .wrap-txt {

                @include media-breakpoint-up(md) {
                    margin: 0 auto;
                    width: 85%;
                }

                .title {
                    font-size: 26px;
                    margin-top: 30px;

                    @include media-breakpoint-up(md) {
                        text-align: left;
                    }
                }

                .description {
                    color: white;
                    display: block;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    text-align: center;
                    margin: 0 auto 15px;
                    line-height: 20px;
                    width: 255px;

                    @include media-breakpoint-up(md) {
                        text-align: left;
                    }
                    @include media-breakpoint-up(lg) {
                        margin-left: 0;
                    }
                }

                .date {
                    color: white;
                    display: block;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                    text-align: center;
                    margin-bottom: 15px;

                    @include media-breakpoint-up(md) {
                        text-align: left;
                    }
                }
            }
        }

        .slick-dots {
            li {
                &.slick-active {
                    button {
                        &:before {
                            opacity: 1;
                            color: $brand-light;
                        }
                    }
                }

                button {
                    &:before {
                        opacity: 1;
                        font-size: 14px;
                        color: $back-gray;
                    }
                }
            }
        }
    }

}

//.sd-block-6

.sd-contact {
    background-image: url("/images/background-contact-xs.webp");
    background-position: 0 0;
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 105px;
    padding-top: 75px;

    @include media-breakpoint-up(md) {
        background-image: url("/images/background-contact.webp");
        background-position: center center;
        background-size: cover;
    }

    .wrap-contact {
        .text {
            margin: 0 auto;
            text-align: center;
            width: 220px;

            @include media-breakpoint-up(md) {
                width: 100%;
            }
            @include media-breakpoint-up(lg) {
                margin-bottom: 40px;
            }
        }
    }

    form {
        width: 100%;

        .form-group {
            margin-top: 68px;

            label {
                display: block;
                text-align: center;
                font-size: 22px;
            }

            input {
                display: block;
                background-color: transparent;
                border-left: 0;
                border-right: 0;
                border-top: 0;
                border-bottom: 1px solid white;
                border-radius: 0;
                margin: 0 auto;
                text-align: center;
                color: white;
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                line-height: 22px; /* 157.143% */
                width: 90%;
            }

            .privacy_notice {
                display: flex;
                align-items: center;
                text-align: center;
                justify-content: center;
                margin: 0 auto 60px;
                width: 300px;

                @include media-breakpoint-up(md) {
                    width: 360px;
                }

                input {
                    width: auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: auto;
                }
                span {
                    margin-left: 15px;
                    font-size: 14px;
                    color: white;

                    @include media-breakpoint-up(md) {
                        font-size: 16px;
                    }

                    a {
                        color: white;
                        text-decoration: underline;
                    }
                }
            }

            button {
                margin: 0 auto;
                width: 240px;

                @include media-breakpoint-up(md) {
                    //margin-left: 25px;
                }
            }
        }
    }

}

.slick-slide {
    img {
        margin: 0 auto;
    }
}