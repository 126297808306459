header {
    background-color: transparent;
    padding-top: 20px;
    padding-bottom: 20px;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10;
    transition: all 250ms ease;

    @include media-breakpoint-up(md) {
        position: fixed;
        padding-top: 40px;
        padding-bottom: 40px;
    }

    &.stick {
        background: rgba(#000, 0.3);
        backdrop-filter: blur(3px);
        padding-top: 20px;
        padding-bottom: 20px;
        transition: all 250ms ease;

        .DskNavBox nav {
            img {
                //opacity: 1;
                //width: 95px;
            }
        }
    }

    .sd-hdrMb {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        .wSpace {
            width: 40px;

            @include media-breakpoint-up(md) {
                width: 5%;
            }
        }

        .hamburger {
            width: 40px;
            z-index: 2;

            span {
                background: white;
                border-radius: 4px;
                display: block;
                height: 3px;
                width: 100%;
                margin-bottom: 7px;
                transition: all 250ms ease;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .sd-logo {
            padding-top: 10px;
            transition: all 250ms ease;
            width: 50%;
            @include media-breakpoint-up(sm) {
                width: 30%;
            }
        }

        .btnLined {
            padding: 7px;
        }
    }

    .DskNavBox nav {
        display: none;
        @include media-breakpoint-up(lg) {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            width: 100%;
        }

        img {
            margin: 0 auto;
            transition: all 250ms ease;
            @include media-breakpoint-up(lg) {
                width: 186px;
            }

        }

        ul {
            li {

                @include media-breakpoint-up(md) {
                    font-size: 13px;
                }
                @media (min-width: 1199px) {
                    font-size: 16px;
                }

                &.lang {
                    @include media-breakpoint-up(md) {
                        width: 50px;
                    }
                    @media (min-width: 1199px) {
                        font-size: 14px;
                        width: 120px;
                    }

                    a {
                        &.small {
                            @include media-breakpoint-up(md) {
                                width: 60px;
                            }
                            @media (min-width: 1199px) {
                                width: 120px;
                            }
                            img {
                                @include media-breakpoint-up(md) {
                                    opacity: 1;
                                    width: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .sd-navLeft {
            margin: 0 auto;
        }
    }

    ul {
        color: white;
        margin: 0;
        padding: 0;
        list-style-type: none;

        @include media-breakpoint-up(lg) {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        li {
            margin-right: 35px;
            text-align: center;
            text-transform: uppercase;
            width: 100%;

            &.social-media {
                display: flex;
                align-items: center;
                justify-content: space-around;

                a {
                    border: 1px solid white;
                    border-radius: 28px;
                    display: flex;
                    height: 40px;
                    width: 145px;
                    line-height: normal;
                    justify-content: center;
                    align-items: center;

                    label {
                        margin: 0;
                    }

                    img {
                        height: 25px;
                        width: 25px;
                        opacity: 1;
                        margin: 0 8px 0 0;
                    }
                }
            }

            @include media-breakpoint-up(md) {
                margin-right: 0;
            }

            img {
                opacity: 0;
                margin: 0 auto;
            }
        }

        &.sd-navRight {
            @include media-breakpoint-up(lg) {
                justify-content: flex-end;
                .sd-cta {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    margin-right: 0;

                    span {
                        margin-left: 15px;
                        margin-right: 0;
                    }

                    a:hover {
                        border-color: $brand-secondary;
                        color: $brand-secondary;
                    }
                }
            }

        }
    }
}

.sd-link {
    color: white;
    display: block;
    position: relative;

    &::after {
        background-color: transparent;
        content: "";
        height: 135%;
        width: 0;
        position: absolute;
        right: 0;
        left: 0;
        bottom: -3px;
        margin: 0 auto;
        transition: width 250ms ease-in;
        border-radius: 20px;
    }

    &.active,
    &:hover {
        color: white;

        &::after {
            border: 1px solid $brand-alternative;
            width: 90%;
        }
    }

    &.sm {
        &.active,
        &:hover {
            color: white;

            &::after {
                border: 0;
                width: 0;
            }
        }
    }
}

.sdNav {
    background: $brand-secondary;
    color: white;
    letter-spacing: 5.1px;
    text-align: center;
    text-transform: uppercase;
    padding-top: 80px;
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100vw;
    z-index: 1;
    opacity: 0;
    visibility: hidden;

    .sd-logo {
        display: block;
        margin-top: -67px;
        margin-bottom: 60px;

        @include media-breakpoint-up(md) {
            margin-top: -47px;
        }

        img {
            display: block !important;
            margin: 17px auto 0;
            left: 5px;
            width: 145px;
            position: relative;

            @include media-breakpoint-up(md) {
                left: -3px;
                width: 177px;
            }
        }
    }

    ul {
        display: block;

        li {
            position: relative;
            margin: 0;
            margin-bottom: 48px;
        }

        a {
            font-size: 15px;
            color: white;

            &.small {
                font-size: 12px;

                img {
                    display: inline-block;
                    width: 17px;
                    margin-top: -3px;
                    opacity: 1;
                }
            }
        }
    }

    .btnLined {
        color: $brand-primary;
    }
}
