.is_privacy_notice {

    .sd-banner{

        .HomeBannerBox {

            .item {
                position: relative;

                .container {
                    position: absolute;
                    z-index: 1;
                    top: 50%;
                    padding: 0;
                    line-height: normal;
                    transition: all 0.5s ease-out;

                    @include media-breakpoint-up(sm) {
                        top: 42vh;
                        left: 0;
                        right: 0;
                    }
                    @include media-breakpoint-up(md) {
                        top: 55%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        -ms-transform: translate(-50%, -50%);
                        bottom: unset;
                    }

                    .BannerCaption {
                        color: white;
                        position: relative;
                        text-align: center;
                        transition: all 0.5s ease-out;

                        @include media-breakpoint-up(md) {
                            text-align: left;
                        }

                        > label {
                            color: white;
                            display: block;
                            margin-bottom: 10px;
                            text-align: center;
                            transition: all 0.5s ease-out;
                            font-size: 45px;
                            text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.7);

                            @include media-breakpoint-up(md) {
                                min-height: 90px;
                                margin-bottom: 0;
                                font-size: 90px;
                            }
                            @include media-breakpoint-up(lg) {
                                font-size: 150px;
                            }

                        }

                    }
                }
            }

        }

        //.HomeBannerBox

    }

    //.sd-banner

    .block-text {
        padding: 15px;
        margin-top: 30px;
        margin-bottom: 20px;
        text-align: justify;

        label {
            display: block;
            font-size: 14px;
            margin-bottom: 25px;
        }
    }

    .link {
        color: $brand-secondary;
        display: block;
        text-align: center;
        margin-bottom: 30px;
    }

}