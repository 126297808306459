#content {
    &.sticky {

        .VehicleOwnMenu,
        header,.notifyBar {
            transform: translateY(-58px);

            @include media-breakpoint-up(md) {
                transform: translateY(-71px);
            }


            @include media-breakpoint-up(lg) {
                transform: translateY(-70px);
            }

        }
    }

    &.noScroll {
        height: calc(100vh - 58px);
        overflow: hidden;

        @include media-breakpoint-up(md) {
            height: calc(100vh - 70px);
        }
    }
}

// msg on orientation device to prevent desmadre
.turnOff {
    display: none;
}

.do-overflow {
    overflow: hidden;
}

@keyframes pulse {
    10% {
        transform: scale(1.1);
    }
}

/***********************************************************
GENERAL ELEMENTS
***********************************************************/
a:hover {
    color: #191919;
}

a:active,
a:hover {
    text-decoration: none;

}

a[href*="tel"] {
    color: $brand-alternative;
}

a,
button {
    border: none;

    &:focus {
        outline: none;
        border: none;
    }
}

ul,
ol {
    margin: 0;
    padding: 0;
}

//fix slick spaces
.slick-slide {
    line-height: 0;
}

//box whit shadow
.ShadowBox {
    border-radius: 7px;
    box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.07);
    background-color: #ffffff;
}

//show-hide tabs
.hideTab {
    display: none;
}

.showTab {
    display: block;
}

picture {
    width: 100%;
}

//custom Select

.roundSelect {
    min-width: 160px;
    height: 44px;
    border-radius: 22px;
    padding-left: 20px;
    color: $brand-primary;
    -moz-appearance: none;
    appearance: none;

    &.brd-primary {
        border: solid 2px $brand-primary;
    }

    &.brd-secondary {
        border: solid 2px $brand-light;
    }

    &.back-light {
        background: url("/images/arrow-down-s.svg") #f2f2f2 no-repeat 92% center;
    }

    &.back-white {
        background: url("/images/arrow-down-b.svg") #ffffff no-repeat 92% center;
    }

    &.back-primary {
        background: url("/images/arrow-down-w.svg") transparent no-repeat 92% center;
    }
}

select:focus {
    outline: none;
}

.LineSelect {
    border: none;
    border-bottom: 1px solid #c4c4c4;
    border-radius: 0;
    width: 100%;
    padding-right: 20px;
    color: $brand-third;
    -moz-appearance: none;
    appearance: none;

    &.back-white {
        background: url("/images/arrow-down-b.svg") #ffffff no-repeat right center;
    }
}

/***********************************************************
TEXT COLORS
***********************************************************/

.txtColorLight {
    color: $brand-light;
}

.txtColorPrimary {
    color: $brand-primary;
}
.txtColorPrimary-forced {
    color: $brand-primary !important;
}

.txtColorSecondary {
    color: $brand-secondary;
}

.textThin {
    font-weight: lighter;
}

/***********************************************************
BG COLORS
***********************************************************/

.bgColorLight {
    background: $brand-light;
}

.bgColorPrimary {
    background: $brand-primary;
}

.bgColorSecondary {
    background: $brand-secondary;
}

/***********************************************************
TITLES
***********************************************************/

.SecTitle {
    color: $brand-text;
    font-size: 44px;
    font-weight: 200;
    text-align: center;
    margin-bottom: 30px;

    @include media-breakpoint-up(md) {
        font-size: 60px;
        margin-bottom: 50px;
    }


    @media #{$mq-2k} {
        font-size: em(30px);
    }
}

.SecSubtitle {
    color: $brand-text;
    font-size: 30px;
    font-weight: 200;
    text-align: center;

    @include media-breakpoint-up(md) {
        font-size: 50px;
    }


    @media #{$mq-2k} {
        font-size: em(30px);
    }
}

//ShortHeaders

.ShortHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 225px;

    @media (min-width: 1600px) {
        height: 290px;
    }

    .ShortHeader-title {
        color: white;
        font-size: em(40px);
        font-weight: 200;
        text-align: center;

        @include media-breakpoint-up(lg) {
            font-size: em(60px);
        }

        @media #{$mq-2k} {
            //font-size: em(70px);
        }
    }
}

/***********************************************************
ACTIONS
***********************************************************/

.BtnStd {
    background-color: transparent;
    border: 1px solid white;
    border-radius: 30px;
    color: white;
    cursor: pointer;
    display: block;
    height: 56px;
    min-width: 120px;
    padding-left: 20px;
    padding-right: 20px;
    text-transform: uppercase;
    font-size: 26px;
    text-align: center;
    width: 200px;
    transition: all 0.5s ease-out;
    font-family: $fontTitle;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;

    @media #{$mq-2k} {
        min-width: 225px;
    }

    &.disabled {
        background-color: #df1d4d !important;

        .iArrow,
        .iArrow:focus,
        .iArrow:hover {
            display: none !important;

        }
    }

    &.aDark {
        background-color: $brand-primary;
    }

    &-light {
        background-color: $brand-light;
        color: $brand-primary;
    }

    &.w-shadow {
        box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.29);
    }

    &:hover {
        color: white;
        background-color: $brand-secondary;
    }
}

.SingleLink {
    display: inline;
    font-size: em(12px);
    position: relative;
    padding-bottom: 5px;

    &::after {
        background-color: $brand-primary;
        content: "";
        height: 2px;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
    }
}

.BtnRounded {
    border-radius: 100%;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 500ms ease;

    &-w-brdr {
        border: 1px solid $brand-secondary;
    }
}

.UnderLine-fx {
    color: $brand-third;
    position: relative;
    overflow: hidden;
    transition: color 500ms ease;

    &::after {
        background-color: $brand-primary;
        content: "";
        width: 100%;
        height: 2px;
        position: absolute;
        bottom: 0;
        left: 0;
        transform: scaleX(0) translate3D(0, 0, 0);
        transform-origin: right;
        transition: 0.6s transform cubic-bezier(1, 0, 0, 1);
        z-index: 1;
    }

    &:hover {
        color: $brand-primary;

        &::after {
            transform: scaleX(1) translate3D(0, 0, 0);
            transform-origin: left;
        }
    }


    &.current,
    &.active {
        color: $brand-primary;

        &::after {
            transform: scaleX(1) translate3D(0, 0, 0);
            transform-origin: left;
        }
    }
}

.StaticUnderLine {
    font-size: em(14px);
    display: inline-block;
    position: relative;
    padding-bottom: 2px;
    transition: all 500ms ease;

    &.UndrLn-primary {
        border-bottom: 2px solid $brand-primary;
        color: $brand-primary;
    }

    &.UndrLn-secondary {
        border-bottom: 2px solid $brand-secondary;
        color: $brand-secondary;
    }
    &.UndrLn-ligth{
        border-bottom: 2px solid white;
        color: white;
    }
    &.u-link {
        font-size: em(12px);

        &:hover {
            &.UndrLn-primary {
                border-bottom: 2px solid $brand-secondary;
                color: $brand-secondary;
            }

            &.UndrLn-secondary {
                border-bottom: 2px solid $brand-primary;
                color: $brand-primary;
            }
            &.UndrLn-ligth {
                border-bottom: 2px solid $brand-primary;
                color: $brand-primary;
            }
        }
    }

    &.txtBold {
        font-weight: 700;
    }

    &.txtBigLight {
        font-size: em(26px);
        font-weight: 200;
    }
}

/***********************************************************
COMMON ELEMENTS
***********************************************************/

.pdVTop {
    padding-top: 100px;
}

.BuilElemMain {
    padding: 50px 0;

    @include media-breakpoint-up(md) {
        padding: 65px 0 75px 0;
    }
}

.BuildElem {
    text-align: center;
    margin-bottom: 15px;

    &.onColumn {
        @include media-breakpoint-down(sm) {
            &:nth-child(1) {
                padding-right: 7px;
            }

            &:nth-child(2) {
                padding-left: 7px;
            }
        }
    }

    .BuildElem-inner {
        color: $brand-secondary;
        display: block;
        padding: 25px 15px;
        height: 100%;

        @include media-breakpoint-up(md) {
            padding: 40px 15px;
        }


        h3 {
            font-size: em(24px);
            font-weight: 200;

            @include media-breakpoint-up(md) {
                font-size: em(26px);
            }


            @include media-breakpoint-up(lg) {
                font-size: em(30px);
            }


            +span {
                display: block;
                font-size: em(12px);
                margin-bottom: 10px;
                min-height: 40px;

                @include media-breakpoint-down(sm) {
                    br {
                        display: none;
                    }
                }
            }
        }
    }
}

.BuilElem-row {
    .BuildElem {
        @include media-breakpoint-down(sm) {
            &.wideElem {
                .BuildElem-inner {
                    display: flex;
                    justify-content: center;

                    figure {
                        width: auto;
                        margin: 0 10px 0 0;
                    }

                    h3 {
                        +span {
                            min-height: 15px;
                        }
                    }
                }
            }

            .StaticUnderLine {
                //display: none;
            }
        }
    }
}

.Buttons-set {
    text-align: center;
    margin-top: 50px;
}

//video
.VideoContainer {
    position: relative;

    .icon-play-red {
        cursor: pointer;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(1);
        transition: transform 500ms ease;
        z-index: 1;

        &:hover {
            transform: translate(-50%, -50%) scale(1.1);
        }
    }
}

.common-contact {
    @include media-breakpoint-up(md) {
        justify-content: center;
    }


    &.wideColumns {
        @include media-breakpoint-down(sm) {
            .wideElem {
                a {
                    display: flex;
                    flex-wrap: wrap;
                    margin-right: -15px;
                    margin-left: -15px;
                    align-items: center;

                    figure {
                        margin-left: 8.3333333333%;
                        margin-bottom: 0;
                        width: 25%;
                    }

                    span {
                        width: 58.333333%;
                    }

                    h3 {
                        font-size: em(24px);
                        margin: 0;
                    }
                }
            }
        }
    }
}

.OverlayMenu {
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    opacity: 0;
    visibility: hidden;

    &.outSide {
        opacity: 1;
        visibility: visible;
        z-index: 3;
    }
}

.SdwItem {
    transition: all 500ms ease;

    &:hover {
        border-radius: 7px;
        box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.07);
        background-color: #ffffff;
    }
}

.SltcItem {
    border: 2px solid transparent;

    /* &::after,
        &::before {
            content: "";
            position: absolute;
            transition: all 500ms ease;
        }

        &::before {
            background-color: $brand-primary;
            border-top-right-radius: 7px;
            content: "";
            height: 34px;
            width: 34px;
            opacity: 0;
            position: absolute;
            top: -2px;
            right: -2px;
            z-index: 1;
        }

        &::after {
            background: url(/images/icon-ok.svg) no-repeat center center;
            background-size: contain;
            content: "";
            height: 15px;
            width: 22px;
            position: absolute;
            top: 9px;
            right: 5px;
            z-index: 2;
            opacity: 0;
            transform: scale(0.2);
        }*/

    &.active {
        border: 2px solid $brand-primary;

        &::before {
            opacity: 1;
        }

        &::after {
            opacity: 1;
            transform: scale(1);
        }
    }
}

/***********************************************************
    CONTAINER
    ***********************************************************/
.container {
    @media #{$mq-2k} {
        max-width: 1550px;
    }
}

label {
    &.error {
        color: $brand-error;
    }
}
//estimate
.EstimateForm {
    .EstimateForm-group {
        margin-bottom: 10px;
    }

    label {
        color: $brand-third;
        font-size: em(14px);
        display: block;
        padding-left: 10px;
    }

    select {
        background: url("/images/arrow-down-b.svg") #fbfbfb no-repeat 95% center;
        width: 100%;
        height: 50px;
        border-radius: 4px;
        border: solid 1px #dedede;
        box-shadow: inset 0 1px 7px 0 rgba(0, 0, 0, 0.13);
        color: $brand-primary;
        -moz-appearance: none;
        -ms-appearance: none;
        appearance: none;
        font-size: em(22px);
        padding-left: 20px;
        text-transform: uppercase;

        @media #{$mq-2k} {
            font-size: em(16px);
        }
    }

    select:focus {
        outline: none;
    }

    textarea {
        height: 110px;
        max-height: 110px;
        min-height: 110px;
        max-width: 100%;
        min-width: 100%;
        overflow: auto;
        padding-top: 5px;
    }
}

.EstimateResult {
    background-color: #fff;
    border-radius: 5px;
    border: solid 1px #dcdcdc;
    display: flex;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    position: relative;

    .EstimateTitle {
        @include media-breakpoint-up(md) {
            font-size: em(18px);
        }
    }

    .EstimateAmount {
        color: $brand-primary;
        font-size: em(22px);
        font-weight: 700;
        text-align: right;

        @include media-breakpoint-up(md) {
            font-size: em(30px);
        }
        @include media-breakpoint-up(lg) {
            font-size: em(25px);
        }
        @include media-breakpoint-up(xl) {
            font-size: em(28px);
        }

        small {
            font-size: em(15px);
            margin-right: 5px;
            font-weight: bold;

            @include media-breakpoint-up(lg) {
                font-size: em(12px);
                margin-right: 5px;
            }
            @include media-breakpoint-up(xl) {
                font-size: em(10px);
            }
        }
    }
    .wait-for-change {
        background-color: rgba(0, 0, 0, 0.4);
        display: none;
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        text-align: center;

        &.active {
            display: block;
        }

        img {
            width: 56px;
            padding-top: 10px;
        }
    }
}

.EstimateDisclamer {
    color: $brand-alternative;
    font-size: em(10px);
    text-align: center;
    padding: 20px 0;

    @include media-breakpoint-up(lg) {
        padding: 5px 15px;
    }


    @media #{$mq-2k} {
        font-size: em(8px);
        padding-bottom: 20px;
        padding-top: 20px;
    }
}

.EstimateLead {
    .common-contact {
        display: flex;
        flex-wrap: wrap;
    }

    .BuildElem {
        padding: 0 5px;

        .BuildElem-inner {
            padding: 20px 15px;

            h3 {
                font-size: em(18px);
            }
        }
    }
}

//generic inut style
input,
textarea {
    color: $brand-primary;
    background-color: #fbfbfb;
    border-radius: 4px;
    box-shadow: inset 0 1px 7px 0 rgba(0, 0, 0, 0.13);
    border: solid 1px #dedede;
    font-size: em(22px);
    height: 45px;
    width: 100%;
    padding-left: 20px;

    @media #{$mq-2k} {
        font-size: em(16px);
    }

    &:focus {
        outline: none;
    }

    &.error {
        border: 1px solid $brand-primary;
    }
}

::-webkit-input-placeholder {
    /* Edge */
    color: $brand-third;
    font-size: 16px;
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $brand-third;
    font-size: 16px;
}

::placeholder {
    color: $brand-third;
    font-size: 16px;
}

.generic-car {
    margin: auto;
}

.headWrap {
    //padding-top: 23px;

    @include media-breakpoint-up(sm) {
        //padding-top: 53px;
    }


    //media-brackpoint

    h1 {
        color: #191919;
        font-size: em(14px);
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        margin-bottom: 0;

        @include media-breakpoint-up(sm) {
            font-size: em(16px);
        }


        //media-brackpoint

        span {
            font-weight: bold;
        }

        //span

        label {
            display: block;
            font-size: em(40px);
            font-weight: 200;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            margin-bottom: 20px;

            @include media-breakpoint-up(sm) {
                font-size: em(60px);
                margin-bottom: 17px;
            }


            //media-brackpoint

        }

        //label

    }

    // h1
}

//.headWrap

//
.swal-honda {
    button.swal2-confirm.swal2-styled {
        background-color: #ed1b2f;
        color: white;
        border-radius: 25px;
        font-size: 0.75em;
        font-weight: 700;
        display: inline-flex;
        text-transform: uppercase;
        padding: 15px 20px;
        min-width: 150px;
        justify-content: center;
        transition: all 500ms ease;
        font-family: "Nunito Sans", sans-serif;

        &:focus {
            outline: 0;
            box-shadow: initial;
        }

        &:hover {
            background-color: #df1d4d !important;
        }

    }
}

h2.empty-collection {
    margin: 0 auto;
    border-radius: 7px;
    box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.07);
    background-color: #ffffff;
    width: 80%;
    text-align: center;
    padding: 15px 0px
}

.color-clear {
    color: transparent !important;
}

.check-box {
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    height: 50px !important;

    .box {
        display: inline-block;
        width: 40px;
        height: 37px;
        line-height: 50px;
        vertical-align: middle;
        border-radius: 8px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
        border: solid 1px #dbdbdb;
        background-color: #ffffff;
        text-align: center;

        input {
            display: none;
        }

        img {
            display: none;
            margin: 0 auto;
            width: 89%;
            top: -8px;
            position: relative;
        }

        input:checked+img {
            display: inline-block;
        }
    }

    span {
        display: inline-block;
        height: 50px;
        line-height: 50px;
        vertical-align: middle;
        margin-left: 17px;
    }
}

.no-margin {
    margin: 0 !important;
}
.w-margin {
    margin-left: 15px;
    margin-right: 15px;
}
.no-padding {
    padding: 0 !important;
}
.w-padding {
    padding-left: 15px;
    padding-right: 15px;
}
.w-padding-f {
    padding-left: 15px !important;
    padding-right: 15px !important;
}


.swal2-close {
    background-image: url('/images/icon-close-red.svg?v=2') !important;
    background-position: center center!important;
    background-repeat: no-repeat!important;
    //display: block!important;
    overflow: hidden!important;
    width: 43px!important;
    height: 43px!important;
    border-radius: 50%!important;
    padding: 25px!important;
    color: transparent!important;
    user-select: none!important;
    position: absolute!important;
    left: auto!important;
    right: 15px!important;
    top: 15px!important;

    @include media-breakpoint-up(md) {
        margin: 44px;
    }
    @include media-breakpoint-up(lg) {
        top: -20px !important;
    }
}

.margin-center {
    margin-left: auto;
    margin-right: auto;
}


.form-1 {
    background-image: url('/images/forma-1.svg');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: 4px -29px;
    position: absolute;
    right: 0;
    top: 0;
    height: 26px;
    width: 50px;
    animation: float 3s ease-in-out infinite;

    @include media-breakpoint-up(xl) {
        background-position: 5px -70px;
        right: 0;
        top: 0;
        height: 130px;
        width: 180px;
    }
}
.form-2 {
    background-image: url("/images/forma-1.svg");
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: 100% 0;
    position: absolute;
    top: 105%;
    left: 0;
    transform: translate(0, -40%);
    height: 155px;
    width: 65px;
    z-index: 1;
    animation: float 10s ease-in-out infinite;

    @include media-breakpoint-up(xl) {
        top: 110%;
        height: 240px;
        width: 120px;
    }
}
.form-3 {
    position: absolute;
    top: 105%;
    left: 0;
    width: 120px;
    transform: translate(0, -40%);

    .wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        filter: url('#filter');
    }

    .ball {
        width: $radius;
        height: $radius;
        position: absolute;
        top: calc(50% - #{$radius/2});
        left: calc(50% - #{$radius/2});

        &:before {
            background: $brand-third;
            border-radius: 100%;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: '';
        }

        &:nth-child(1) {
            transform: translate(-$radius*1.1);
            animation: x-axis-lateral 4s infinite alternate ease-in-out;
            &:before {
                animation: y-axis-lateral 2s infinite 0.2s alternate ease-in-out;
            }
        }

        &:nth-child(2) {
            animation: x-axis 4s infinite alternate ease-in-out;
            &:before {
                animation: y-axis 2s infinite 1s alternate ease-in-out;
            }
        }

        &:nth-child(3) {
            transform: translate($radius*1.1, $radius*1.3);
            animation: x-axis-lateral 4s infinite alternate ease;
            &:before {
                animation: y-axis-lateral 2s infinite .8s alternate ease-in-out;
            }
        }
    }
}

.title {
    color: $brand-primary;
    display: block;
    text-align: center;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;

    @include media-breakpoint-up(lg) {
        font-size: 48px;
    }

    .blue {
        color: $brand-third;
    }
}
.subTitle {
    color: $brand-primary;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-transform: uppercase;
}
p {
    color: $brand-text;
}

.wrap-whatsCotiza {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 0;
    bottom: 5%;
    width: 67px;
    z-index: 10;
    transition: all 0.5s ease-out;

    @include media-breakpoint-up(md){
        width: 160px;
    }

    @media (min-width: 1600px) {
        right: 5%;
    }

    a {
        border-radius: 30px;
        display: block;
        padding-left: 20px;
        width: 100%;

        img {
            margin-right: 10px;
        }

        &.whats {
            background-color: $brand-alternative;
            color: white;
            margin-bottom: 10px;
            line-height: 45px;
            height: 45px;
        }
        &.quote {
            background-color: $brand-primary;
            color: white;
            line-height: 45px;
            height: 45px;

            img {
                width: 23px;
            }
        }
    }
}

.arrows-slider {
    position: absolute;
    margin: 0 auto;
    top: 42%;
    width: 100%;
    transform: translate(-50%, 50%);
    left: 50%;

    li {
        list-style: none;
        position: relative;
        filter: drop-shadow(5px 3px 4px #000);;

        &.prev {
            background-position: 0 0;
            background-image: url("/images/prev-arrow.svg");
            background-size: 100% auto;
            left: 0;
            right: auto;
            width: 34px;
            height: 65px;
            position: absolute;
        }
        &.next {
            background-position: 0 0;
            background-image: url("/images/next-arrow.svg");
            background-size: 100% auto;
            right: 0;
            left: auto;
            width: 34px;
            height: 65px;
            position: absolute;
        }
    }
}

.swal2-container {
    background: rgba(0, 40, 86, 0.8) !important;
    .swal2-close {
        background-color: $brand-alternative;
        background-image: url('/images/icon-close.webp') !important;
        background-size: 24px auto;
        background-repeat: no-repeat !important;
        background-position: center center !important;
        border-radius: 0 !important;
        height: 32px !important;
        top: -50px !important;
        width: 32px !important;
        right: 0px !important;

        &:hover {
            background-color: $brand-alternative !important;
            background-image: url('/images/icon-close.webp') !important;
            background-size: 24px auto !important;
        }
    }
    .swal2-popup {
        border-radius: 0;
        padding: 0;
        background-color: transparent;

        @include media-breakpoint-up(lg) {
            width: 1100px;
        }
        @media (min-width: 1600px) {
            width: 1400px;
        }
        @media (min-width: 1800px) {
            width: 1600px;
        }

        .swal2-html-container {
            margin: 0;
        }
    }
}

.slick-inflate-publicity {
    //min-width: 1200px;
}

.img-circle {
    img {
        &.animate {

            &.bounce {
                animation: bounce alternate infinite cubic-bezier(.2, .65, .6, 1);
                --bounce-offset: -20px;
                animation-duration: 300ms;
            }
            &.wiggle {
                animation: wiggle 3s linear infinite;
            }
            &.jelly {
                animation: jelly 4s ease-in-out infinite;
            }
            &.squish {
                animation: squish 2s ease-in infinite;
            }
        }
    }
}

@keyframes float {
    0% {
        transform: translatey(0px);
    }
    50% {
        transform: translatey(-80px);
    }
    100% {
        transform: translatey(0px);
    }
}
@keyframes moveR {
    0% {
        transform: translateX(0px);
    }
    50% {
        transform: translateX(-200%);
    }
    100% {
        transform: translateX(0px);
    }
}
@keyframes moveU {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(50%);
    }
    100% {
        transform: translateY(0px);
    }
}
@keyframes flip {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(50deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
@keyframes inflate {
    0% {
        transform: scale(0.6);
    }
    50% {
        transform: scale(1.3);
    }
    100% {
        transform: scale(0.6);
    }
}
@keyframes bounce {
    from {
        transform: translateY(0px);
    }
    to {
        transform: translateY(
                var(--bounce-offset)
        );
    }
}
@keyframes wiggle {
    0%, 7% {
        transform: rotateZ(0);
    }
    15% {
        transform: rotateZ(-15deg);
    }
    20% {
        transform: rotateZ(10deg);
    }
    25% {
        transform: rotateZ(-10deg);
    }
    30% {
        transform: rotateZ(6deg);
    }
    35% {
        transform: rotateZ(-4deg);
    }
    40%, 100% {
        transform: rotateZ(0);
    }
}
@keyframes jelly {
    0% {
        transform: scale(1, 1);
    }
    25% {
        transform: scale(0.9, 1.1);
    }
    50% {
        transform: scale(1.2, 0.8);
    }
    75% {
        transform: scale(0.95, 1.05);
    }
}
@keyframes squish {
    5% {
        transform: scale(1.2, 0.9);
    }
    10% {
        transform: scale(0.9, 1.2) translate(0, -4px);
    }
    15% {
        transform: scale(1);
    }
}


@keyframes x-axis {
    0% { transform: translate(-$radius*1.6); }
    100% { transform: translate($radius*1.6); }
}

@keyframes y-axis {
    0% { transform: translateY($radius*0.7); }
    100% { transform: translateY(-$radius*1.1) scale(.8); background: $brand-third;}
}


@keyframes x-axis-lateral {
    0% { transform: translate(-$radius*.6); }
    100% { transform: translate($radius*.6); }
}

@keyframes y-axis-lateral {
    0% { transform: translateY($radius/5); }
    100% { transform: translateY(-$radius); background: $brand-third;}
}