.is_blog {
    background-image: linear-gradient(180deg, #061C49 40.63%, #041A35 75.52%);

    &.detail {
        background-image: linear-gradient(180deg, white 40.63%, white 75.52%);

        .sd-banner {
            background-color: #061C49;

            .HomeBannerBox {

                .item {

                    .container {

                        @include media-breakpoint-up(md) {
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            -ms-transform: translate(-50%, -50%);
                            bottom: unset;
                        }
                        @include media-breakpoint-up(xl) {
                            transform: translate(-50%, -70%);
                            -ms-transform: translate(-50%, -70%);
                        }

                        @media (min-width: 1600px) {
                            transform: translate(-50%, -120%);
                            -ms-transform: translate(-50%, -120%);
                        }

                        .BannerCaption {


                            > h1 {
                                @include media-breakpoint-up(lg) {
                                    width: 100%;
                                }
                            }

                        }

                    }

                }
            }

        }
    }

    .sd-banner{
        margin-bottom: 60px;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }

        .HomeBannerBox {

            .item {
                position: relative;

                .container {
                    position: absolute;
                    z-index: 1;
                    top: 50%;
                    padding: 0;
                    line-height: normal;
                    transform: translate(-50%, -20%);
                    -ms-transform: translate(-50%, -20%);
                    transition: all 0.5s ease-out;

                    @include media-breakpoint-up(sm) {
                        top: 42vh;
                        left: 0;
                        right: 0;
                    }
                    @include media-breakpoint-up(md) {
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -55%);
                        -ms-transform: translate(-50%, -55%);
                        bottom: unset;
                    }
                    @include media-breakpoint-up(xl) {
                        transform: translate(-50%, -85%);
                        -ms-transform: translate(-50%, -85%);
                    }

                    .BannerCaption {
                        color: white;
                        position: relative;
                        text-align: center;
                        transition: all 0.5s ease-out;

                        @include media-breakpoint-up(md) {
                            text-align: left;
                        }

                        > h1 {
                            color: white;
                            display: block;
                            margin-bottom: 10px;
                            text-align: center;
                            transition: all 0.5s ease-out;
                            font-size: 45px;
                            text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.7);

                            @include media-breakpoint-up(md) {
                                min-height: 84px;
                                margin-bottom: 0;
                                font-size: 50px;
                            }
                            @include media-breakpoint-up(lg) {
                                font-size: 84px;
                                margin: 0 auto;
                                width: 800px;
                            }

                            small {
                                text-align: center;
                                text-overflow: ellipsis;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 22px;
                                text-transform: initial;
                                letter-spacing: initial;
                                width: 300px;
                                margin: 15px auto 0;

                                @include media-breakpoint-up(md) {
                                    width: 585px;
                                }
                            }

                        }

                    }
                }
            }

        }

        //.HomeBannerBox

    }

    //.sd-banner

    .sd-banner-detail {
        margin-top: -60px;
        position: relative;
        transition: all 0.5s ease-out;

        @include media-breakpoint-up(lg) {
            margin-top: -120px;
        }
        @include media-breakpoint-up(xl) {
            margin-top: -250px;
        }

        @media (min-width: 1600px) {
            margin-top: -360px;
        }

        img {
            display: block;
            margin: 0 auto;
            max-width: 1024px;
            transition: all 0.5s ease-out;
        }

        .wrap-btn {
            transition: all 0.5s ease-out;
            @include media-breakpoint-up(xl) {
                margin: 0 auto;
                width: 1024px;
            }
            .goBack {
                background-image: url('/images/white-arrow.webp');
                background-position: center;
                background-size: 10px auto;
                background-repeat: no-repeat;
                color: transparent;
                height: 44px;
                padding: 13px 32px 13px 35px;
                border-radius: 22px;
                border: 1px solid white;
                position: absolute;
                top: 10px;
                left: 5%;
                font-size: 19px;
                font-weight: 300;
                line-height: 21px;
                min-width: 50px;
                width: 50px;
                transition: all 0.5s ease-out;

                @include media-breakpoint-up(lg) {
                    background-position: 20px;
                    color: white;
                    top: -70px;
                    left: 12%;
                    height: 44px;
                    width: 153px;
                }
                @include media-breakpoint-up(xl) {
                    top: -20px;
                    left: 0;
                    position: relative;
                    margin: 0;
                }
            }
        }

    }
    //.sd-banner-detail

    .list {
        position: relative;
        transition: all 0.5s ease-out;

        #particles-js-block-1 {
            display: none;
            height: 115%;
            width: 220px;
            left: 0;
            position: absolute;
            top: -15%;
            z-index: 0;

            @include media-breakpoint-up(md) {
                display: block;
            }
        }

        #particles-js-2-block-1 {
            display: none;
            height: 115%;
            width: 220px;
            right: 0;
            position: absolute;
            top: -15%;
            z-index: 0;

            @include media-breakpoint-up(md) {
                display: block;
            }
        }

        .wrap-posts {
            transition: all 0.5s ease-out;

            @include media-breakpoint-up(md) {
                margin-top: -100px;
            }
            @include media-breakpoint-up(xl) {
                margin-top: -310px;
            }

            .item {
                margin-bottom: 60px;
                transition: all 0.5s ease-out;

                .title {
                    font-size: 26px;
                    text-align: left;
                    margin-top: 15px;
                    transition: all 0.5s ease-out;
                }
                .description {
                    color: white;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                    width: 100%;
                    transition: all 0.5s ease-out;

                    p {
                        color: white;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                        width: 100%;
                        transition: all 0.5s ease-out;
                    }

                    @include media-breakpoint-up(md) {
                        width: 300px;
                    }
                }
                .date {
                    color: white;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                    text-transform: uppercase;
                    margin-top: 20px;
                    transition: all 0.5s ease-out;
                }
            }
        }

        .wrap-bottom {
            background-image: url("/images/blog/back-blog-bottom.webp");
            background-position: center 0;
            background-repeat: no-repeat;
            background-size: cover;
            height: 290px;
            width: 100%;
            transition: all 0.5s ease-out;
        }
    }

    .sd-description {
        max-width: 790px;
        margin: 40px auto 80px;
        transition: all 0.5s ease-out;

        @include media-breakpoint-up(lg) {
            margin: 120px auto 120px;
        }

        #particles-js-block-1 {
            display: none;
            height: 115%;
            width: 220px;
            left: 0;
            position: absolute;
            top: -15%;
            z-index: 0;

            @include media-breakpoint-up(lg) {
                display: block;
            }
        }

        #particles-js-2-block-1 {
            display: none;
            height: 115%;
            width: 220px;
            right: 0;
            position: absolute;
            top: -15%;
            z-index: 0;

            @include media-breakpoint-up(lg) {
                display: block;
            }
        }

        p {
            color: #292929;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
        }
    }

    .share {
        color: #FFF;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px; /* 91.667% */
        text-transform: uppercase;
        width: 190px;
        height: 45px;
        flex-shrink: 0;
        border-radius: 20px;
        background: #05509C;
        margin: 40px auto 0;
        transition: all 0.5s ease-out;

        @include media-breakpoint-up(md) {
        }

        img {
            width: 20px;
            margin-left: 10px;
            margin-top: 2px;
            transition: all 0.5s ease-out;
        }
    }

}