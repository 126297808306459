.bg-sd-blue-500 {
    background-color: #053456;
}

.bg-hover-sd-blue-500 {
    &:hover {
        background-color: #053456;
    }
}

.bg-sd-yellow-500 {
    background-color: #fec42e;
}

.bg-hover-sd-yellow-500 {
    &:hover {
        background-color: #fec42e;
    }
}

.title {
    color: $brand-primary;
    font-family: $fontTitle;
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;

    @include media-breakpoint-up(lg) {
        font-size: 64px;
    }
}

.text {
    color: $brand-primary;
    text-overflow: ellipsis;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;

    @include media-breakpoint-up(lg) {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
    }
}

footer {
    background-color: $brand-footer;
    padding-bottom: 72px;
    padding-top: 84px;
    position: relative;

    @include media-breakpoint-up(lg) {
        padding-bottom: 50px;
        padding-top: 50px;
    }

    .wrap-elements {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include media-breakpoint-up(lg) {
            flex-direction: row;
            margin: 0 auto;
            width: 950px;
        }

        svg {
            @include media-breakpoint-up(lg) {
                margin-right: 50px;
            }

            path {
                fill: white;
            }
        }
        img {
            width: 200px;
        }

        .know-more {
            margin: 0 auto 70px;
            text-align: center;
            width: 300px;

            @include media-breakpoint-up(lg) {
                margin: 55px auto 55px;
            }

            a {
                color: white;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-decoration-line: underline;
                text-transform: uppercase;
                text-align: center;

                @include media-breakpoint-up(lg) {
                }
            }

            label {
                margin-top: 20px;
                color: white;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px; /* 157.143% */
                text-transform: uppercase;
            }
        }
    }

    .social-media {
        margin: 60px auto 70px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 250px;

        a {
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;
            display: block;
            height: 34px;
            width: 34px;

            &.facebook {
                background-image: url("/images/icon-facebook.svg");
            }
            &.instagram {
                background-image: url("/images/icon-instagram.svg");
            }
            &.youtube {
                background-image: url("/images/icon-youtube.svg");
                height: 60px;
                width: 60px;
            }
            &.tiktok {
                background-image: url("/images/icon-tiktok.svg");
                height: 48px;
                width: 48px;
            }
        }
    }
}

.wrap-whatsapp {
    position: fixed;
    right: 6px;
    bottom: 10px;
    height: 45px;
    overflow: hidden;
    width: 48px;
    animation: bounce 1.5s infinite ease-in-out;
    transition: transform 0.3s ease;
    z-index: 1;

    @include media-breakpoint-up(sm) {
        right: 16px;
        height: 58px;
        width: 57px;
    }

    a {
        height: 100%;
        width: 100%;
    }

    img {
        height: auto;
        width: 45px;

        @include media-breakpoint-up(sm) {
            width: 60px;
        }
    }

}

/* Animación de salto */
@keyframes bounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px); /* Altura del salto */
    }
}

/* Efecto adicional al pasar el mouse */
.wrap-whatsapp:hover {
    transform: scale(1.1); /* Ligero aumento de tamaño */
    animation: none;       /* Detiene el salto mientras el mouse está encima */
}
